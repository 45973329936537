import Typed from 'react-typed';
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import {FiArrowRight} from "react-icons/fi";
import HeaderTopNews from '../common/header/HeaderTopNews';
import Copyright from '../common/footer/Copyright';
import AboutOne from '../elements/about/AboutOne';
import OurServices from '../elements/service/OurServices';
import TeamTwo from '../elements/team/TeamTwo';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import OurHeader from "../common/header/OurHeader";
import React from "react";
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";

// This component was originally named BusinessConsulting2 in the template
const Home = () => {

    console.log("Home component loaded");
    return (
        <>
            <SEO title="Outlier Engineering Group"/>
            <main className="page-wrapper">
                <HeaderTopNews/>
                <OurHeader btnStyle="round" HeaderSTyle="header-not-transparent"/>

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 bg-transparent height-850">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <span className="subtitle"><b>ENGINEERING SERVICES</b></span>
                                    <h1 className="title theme-gradient display-two">Outlier &nbsp; <br/> {" "}
                                        <Typed
                                            strings={[
                                                "IoT",
                                                "Software",
                                                "Hardware",
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                        />
                                    </h1>
                                    <p className="description">We aim to help businesses enter new markets by leveraging
                                        the right technology effectively.</p>
                                    {/*<p className="description">We aim to help businesses enter new markets by leveraging*/}
                                    {/*    the right technology effectively and bridging*/}
                                    {/*    the gap between traditional operations and emerging technologies.</p>*/}
                                    {/*<p className="description">We help businesses expand into new markets by using the right technology and bridging the gap between traditional operations and emerging technologies.</p>*/}
                                    <div className="button-group">
                                        <Link className="btn-default btn-medium round btn-icon" to="/contact">Contact
                                            Us <i className="icon"><FiArrowRight/></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator/>

                {/* Start About Area  */}
                <AboutOne/>
                {/* End About Area  */}


                {/* Start Call To Action Area  */}
                <div className="rwt-callto-action-area">
                    <div className="wrapper">
                        <CalltoActionFive />
                    </div>
                </div>
                {/* End Call To Action Area  */}

                <Separator/>
                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="What we can do for you"
                                    title="Some of our Capabilities."
                                    description="We do not THINK we are a perfect fit... <br /> We KNOW we are."
                                />
                                <p>The Outlier Engineering team has over 100 years of experience in a diverse set of industries. That experience allows us to analyze real world problems from unique perspectives. We understand our clients need solutions that balance technical requirements with business requirements. We are truely outliers in the industry as our solutions team never loses site of your primary goals.</p>

                            </div>
                        </div>
                        <OurServices
                            serviceStyle="gallery-style"
                            textAlign="text-start"
                        />
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator/>

                {/* Start Team Area  */}
                <div className="rwt-team-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Our Experts."
                                    title="Our Company Experts."
                                    description="We have a team of highly skilled professionals with a passion for innovation <br /> and a unique blend of technical prowess."

                                    // description="We provide company and finance service for <br /> startups and company business."
                                />
                            </div>
                        </div>
                        <TeamTwo column="col-lg-4 col-md-6 col-12" teamStyle="team-style-default style-two"/>
                    </div>
                </div>
                {/* End Team Area  */}
                <Copyright/>
            </main>
        </>
    )
}
export default Home;
