import React from 'react'
import SEO from "../common/SEO";
import OurHeader from '../common/header/OurHeader';
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamFour from "../elements/team/TeamFour";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import Copyright from "../common/footer/Copyright";

const AboutUs = () => {
    return (
        <>
            <SEO title="About Us"/>
            <main className="page-wrapper">
                {/*<HeaderTopNews />*/}
                <OurHeader btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent"/>

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-850 bg_image" data-black-overlay="8"
                     style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/background_code.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                    <h1 className="title display-one">We are an Engineering <br/> Services Business.</h1>
                                    <h6 className="title display-one theme-gradient">Test it. Trust it.</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator/>

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="What we can do for you"
                                    title="Services provided for you."
                                    description="We are technology problem solvers, solution providers, advisors, <br/> and architects with expertise across a broad range of industries. We are Agile."
                                />
                            </div>
                        </div>
                        <ServiceOne
                            serviceStyle="service__style--1 bg-color-blackest radius mt--25"
                            textAlign="text-center"
                        />
                    </div>
                </div>
                {/* End Service Area  */}

                <div className="rwt-timeline-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <TimelineTwo classVar="no-gradient"/>
                            </div>
                        </div>
                    </div>
                </div>

                <Separator/>

                {/* Start Elements Area  */}
                <div className="rwt-counterup-area pb--100">
                    <div className="container mt_dec--30">
                        <CounterUpFour column="col-lg-3 col-md-6 col-sm-6 col-12" counterStyle="counter-style-4"
                                       textALign="text-center"/>
                    </div>
                </div>
                {/* End Elements Area  */}

                <Separator/>

                {/* Start Elements Area  */}
                <div className="rwt-team-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--20">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Our Outlier Team."
                                    title="Engineering Members."
                                    description=""
                                />
                            </div>
                        </div>
                        <TeamFour column="col-lg-6 col-xl-4 col-md-6 col-12 mt--30" teamStyle="team-style-three"/>
                    </div>
                </div>
                {/* End Elements Area  */}
                <Copyright/>
            </main>
        </>
    )
}

export default AboutUs;
