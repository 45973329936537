import React, {useState} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop'
import themeContext from './context/themeContext';

// Pages import Here
import Home from "./pages/Home";
import WaysideWatcher from "./pages/WaysideWatcher";
import AboutUs from "./pages/AboutUs";
import Contact from "./elements/contact/Contact";
import Error from "./pages/Error";

// Import Css Here
import './assets/scss/style.scss';

const App = () => {
    // console.log("App component loaded");
    const [darkTheme, setDarkTheme] = useState(true);

    return (
        <themeContext.Provider value={{darkTheme, setDarkTheme}}>

            <Router>
                <PageScrollTop>
                    <Switch>
                        <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={Home}/>
                        <Route path={`${process.env.PUBLIC_URL + "/wayside-watcher"}`} exact component={WaysideWatcher}/>
                        <Route path={`${process.env.PUBLIC_URL + "/about-us"}`} exact component={AboutUs}/>
                        <Route path={`${process.env.PUBLIC_URL + "/contact"}`} exact component={Contact}/>
                        <Route path={`${process.env.PUBLIC_URL + "/error"}`} exact component={Error}/>
                    </Switch>
                </PageScrollTop>
            </Router>
        </themeContext.Provider>)
}

export default App
