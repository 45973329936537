import React, {useContext} from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import themeContext from "../../context/themeContext";

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const ServiceList = [
    {
        title: 'Hardware Features',
        description:
            '<div style="display: flex; justify-content: space-between;">' +
            '<ul style="width: 100%;">' +
            '<li>RailSmart Network Switch</li>' +
            '<li>Arema Compliant Power</li>' +
            '<li>Optional Cellular</li>' +
            '<li>Optional Fiber</li>' +
            '</ul></div>'
    },
    {
        title: 'Smart Capabilities',
        description:
            '<b>Native communication</b><br/>' +
            '<div style="display: flex; justify-content: space-between;">' +
            '<ul style="width: 50%;">' +
            '<li>Sear</li>' +
            '<li>GCP</li>' +
            '<li>XP4</li>' +
            '</ul>' +
            '<ul style="width: 50%;">' +
            '<li>WSDMM</li>' +
            '<li>DAU</li>' +
            '<li>Reco</li>' +
            '</ul></div>' +
            '<b>Can translate device alerts to any desired back office system</b>' +
            '</ul></div>'

    },
    {
        title: 'WK316',
        description:
            '<b>Enhanced Replacement Device for the Siemens WAG</b><br/>' +
            '<div style="display: flex; justify-content: space-between;">' +
            '<ul style="width: 40%;">' +
            '<li>ATCS over Echelon</li>' +
            '<li>ATCS over Genisys serial</li>' +
            '<li>ATCS over Ethernet</li>' +
            '</ul>' +
            '<ul style="width: 55%;">' +
            '<li>Bridges Siemens devices to non-Siemens systems</li>' +
            '<li>Bridges XP4, WSDMM, DAU to Siemens WAMS & CAMS</li>' +
            '</ul></div>'

    },
]
const WW_HW_Services = ({textAlign, serviceStyle}) => {
    const {darkTheme} = useContext(themeContext);

    const theme = useTheme();
    const matchesWidth = useMediaQuery(theme.breakpoints.between('md','lg'))

    return (
        <div className="row row--15 service-wrapper">
            {ServiceList.map((val, i) => (
                <div className="col-lg-4 col-md-6 col-sm-12 col-12" key={i}>
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="inner">
                                <div className="content" style={{height: matchesWidth ? '440px' : '350px'}}>
                                    <h4 className="title">
                                        <div to="#service" dangerouslySetInnerHTML={{__html: val.title}}></div>
                                    </h4>
                                    <p className="description" dangerouslySetInnerHTML={{__html: val.description}}></p>
                                </div>
                                <div className="image">
                                    {val.dmImage ? <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        height: '200px',
                                        paddingTop: '30px',
                                        paddingBottom: '5px'
                                    }}>

                                        {darkTheme ? <img src={`${val.dmImage}`} alt="dark mode card Images"/> :
                                            <img src={`${val.lmImage}`} alt="light mode card Images"/>}
                                    </div> : <div></div>
                                    }
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default WW_HW_Services;
