import React from 'react';
import {FiLinkedin} from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const teamData = [
    {
        image: 'team-james-01',
        fName: 'James',
        lName: 'Barge',
        // designation1: 'SW Engineer',
        designation1: 'SW Architect',
        designation2: 'HW Engineer',
        description: '10+ Years of Industry Experience in embedded firmware & application development. Years of experience architecting optimized software solutions that meet requirements.',
        socialNetwork: []

    },
    {
        image: 'team-jason-01',
        fName: 'Jason',
        lName: 'Cobb',
        // designation1: 'HW Engineer',
        designation1: 'HW Engineer',
        designation2: 'SW Solutions Architect',
        description: '20+ Years of Industry Experience in hardware design, embedded, & enterprise application development. Proven track record of design solutions that meet customer needs.',
        socialNetwork: []

    },
    {
        image: 'team-kameel-01',
        fName: 'Kameel',
        lName: 'Fakhoury',
        // designation1: 'SW Engineer',
        designation1: 'SW Architect',
        designation2: 'Validation Engineer',
        description: '35+ Years of Industry Experience in embedded firmware & enterprise application development. Extensive experience with software validation and testing.',
        socialNetwork: []
    },
    {
        image: 'team-david-01',
        fName: 'David',
        lName: 'Meadows',
        designation: 'Senior SW Engineer | Full Stack Engineer',
        // designation1: 'SW Engineer',
        designation1: 'Full Stack Engineer',
        designation2: 'Cloud Developer',
        description: '10+ Years of Experience in software systems design & application development. Proficient in full stack dev, cloud architecture, & DevOps. Experienced in validating application software.',
        socialNetwork: [
            // {
            //     icon: <FiLinkedin/>,
            //     url: '#'
            // },
        ]

    },
    {
        image: 'team-nghiep-01',
        fName: 'Nghiep',
        lName: 'Thai',
        designation: 'Senior SW Engineer | Systems Engineer',
        designation1: 'Systems Engineer',
        designation2: 'Embedded SW Engineer',
        description: '25+ Years of Industry Experience in embedded & enterterprise database application development. Years of experience in ensuring designs meet customer and regulatory requirements.',
        socialNetwork: []

    },
    {
        image: 'team-onDemand-01',
        fName: 'On Demand',
        lName: 'Labor',
        designation1: 'SW Engineering',
        designation2: 'Manufacturing',
        description: 'Should the need arise for non-embedded applications we have access to highly skilled low cost software developers with similar cultures and work ethics.',
        socialNetwork: []
    },
]

const TeamTwo = ({column, teamStyle}) => {
    return (
        <div className="row row--15">
            {teamData.map((data, index) => (
                <div className={`${column}`} key={index}>
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                        <div className={`rn-team ${teamStyle}`}>
                            <div className="inner">
                                <figure className="thumbnail">
                                    <img src={`./images/team/${data.image}.jpg`} alt="Corporate React Template"/>
                                </figure>
                                <figcaption className="content">
                                    <h2 className="title">{data.fName}<br/>{data.lName}</h2>
                                    <h6 className="subtitle theme-gradient">{data.designation1}<br/>{data.designation2}<br/>{data.designation3}
                                    </h6>

                                    {/*<span className="team-form">*/}
                                    {/*    <img src="./images/team/location.svg" alt="Corporate React Template" />*/}
                                    {/*    <span className="location">{data.location}</span>*/}
                                    {/*</span>*/}
                                    <p className="description">{data.description}</p>

                                    <ul className="social-icon social-default icon-naked mt--20">
                                        {data.socialNetwork.map((social, index) =>
                                            <li key={index}><a href={`${social.url}`}>{social.icon}</a></li>
                                        )}
                                    </ul>
                                </figcaption>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}

export default TeamTwo;
