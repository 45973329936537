import Typed from 'react-typed';
import SEO from "../common/SEO";
import Copyright from '../common/footer/Copyright';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import OurHeader from "../common/header/OurHeader";
import React from "react";
import WW_SW_Services from "../elements/service/WW_SW_Services";
import WW_HW_Services from "../elements/service/WW_HW_Services";
import WW_BO_Services from "../elements/service/WW_BO_Services";
import HeaderTopNews from "../common/header/HeaderTopNews";

// This component was originally named BusinessConsulting2 in the template
const WaysideWatcher = () => {

    return (
        <>
            <SEO title="Wayside Watcher Platform"/>
            <main className="page-wrapper">
                <HeaderTopNews/>
                <OurHeader btnStyle="round" HeaderSTyle="header-not-transparent"/>

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 bg-transparent height-850">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'center',
                                    paddingBottom: '10px'
                                }}>
                                    <img width={'180px'}
                                         src={`${process.env.PUBLIC_URL}/images/logo/wayside-watcher.png`}
                                         alt="Wayside Watcher Platform Logo"/>
                                </div>
                                <div className="inner text-center">
                                    <h1 className="title theme-gradient display-two">Wayside Watcher &nbsp; <br/> {" "}
                                        <Typed
                                            strings={[
                                                "Monitoring",
                                                "Prediction",
                                                "Integration",
                                                "Reporting",
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                        />
                                    </h1>

                                    <p className="description">The Wayside Watcher platform provides real-time
                                        monitoring and predictive maintenance for railroad assets, enhancing efficiency,
                                        safety, and reliability.
                                        It supports modern and legacy devices, delivering real-time insights and
                                        seamless integration with existing systems.</p>
                                    <div className="button-group">
                                        <a className="btn-default btn-medium round btn-icon"
                                           href="/pdfs/wayside-watcher-presentation.pdf" download>
                                            <span>Download PDF</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator/>
                {/* Start SW Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Elevate Your Rail Operations with Smart Monitoring"
                                    title="Software Services"
                                    description="Real-Time Insights for Rail Asset Optimization"
                                />
                            </div>
                        </div>
                        <WW_SW_Services
                            serviceStyle="gallery-style"
                            textAlign="text-start"
                        />
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator/>

                {/* Start HW Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Optimize Your Rail Operations with Advanced Hardware"
                                    title="Hardware Services"
                                    description="Enhancing Efficiency with Cutting-Edge Hardware Solutions"
                                />
                            </div>
                        </div>
                        <WW_HW_Services
                            serviceStyle="gallery-style"
                            textAlign="text-start"
                        />
                    </div>
                </div>
                {/* End Service Area  */}

                {/* Start BO Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Streamline Operations with Efficient Back Office Services"
                                    title="Back Office Services"
                                    description="Improve Your Workflow with Our Comprehensive Back Office Solutions"
                                />
                            </div>
                        </div>
                        <WW_BO_Services
                            serviceStyle="gallery-style"
                            textAlign="text-start"
                        />
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator/>
                <Copyright/>
            </main>
        </>
    )
}
export default WaysideWatcher;
