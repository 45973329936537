import React from 'react';
import CountUp from 'react-countup';
import TrackVisibility from "react-on-screen";

const Data = [
    {
        countNum : 100,
        countTitle1: 'Years of Engineering',
        countTitle2: 'Experience',

    },
    {
        countNum : 15,
        countTitle1: 'Number of ',
        countTitle2: 'Degrees & Certifications',
    },
    {
        countNum : 15000,
        unit: 'K',
        countTitle1: 'Number of',
        countTitle2: 'Devices Monitored',
    },
    {
        countNum : 10000000,
        countTitle1: 'Supported Msg Rates',
        countTitle2: 'Per Minute',

    },
];
const CounterUpFour = ({textALign, counterStyle, column}) => {
    return (
        <div className="row">
            {Data.map((data, index) => (
                <div className={`${column}`} key={index}>
                    <div className={`count-box ${counterStyle} ${textALign}`}>
                        <TrackVisibility once>
                            {({ isVisible }) => isVisible &&
                                <div className="count-number">{isVisible ? <CountUp end={data.countNum} separator={','} /> : 0}</div>}
                        </TrackVisibility>
                        <h5 className="counter-title">{data.countTitle1}<br/>{data.countTitle2}</h5>
                    </div>
                </div>
            ))}
        </div>
    )
}
export default CounterUpFour;
