import React, {useContext} from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import themeContext from "../../context/themeContext";

import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const ServiceList = [
    {
        title: 'Cloud-based System',
        description:
            '<b>Flexible Deployment</b><br/>' +
            '<li>Host in-house or client cloud</li>' +
            '<li>Leverages existing IT investments</li>' +
            '<b>Scalable</b><br/>' +
            '<li>Handles increased data volumes</li>' +
            '<li>optimal performance during peak times</li>' +
            '<b>Seamless Integration</b><br/>' +
            '<li>Integrates with existing IT ecosystems</li>' +
            '<li>Minimal disruption during adoption</li>'
    },
    {
        title: 'Highly Secure',
        description:
            '<b>End-to-End Encryption</b><br/>' +
            '<li>Secures communications using TLS/SSL</li>' +
            '<b>Data Storage Security</b><br/>' +
            '<li>Advanced encryption for data at rest</li>' +
            '<li>Rigorous access controls & security audits</li>' +
            '<b>Secure Transmission</b><br/>' +
            '<li>Encrypted channels for data in transit</li>'
    },
    {
        title: 'Highly Redundant',
        description:
            '<b>Failover Mechanisms</b><br/>' +
            '<li>Multiple layers of redundancy</li>' +
            '<li>Automated backup processes</li>' +
            '<b>High Availability</b><br/>' +
            '<li>Reduces downtime</li>' +
            '<li>Ensures continuous operation</li>' +
            '<b>Disaster Recovery</b><br/>' +
            '<li>Regular data backups and recovery drills</li>'
    },
]
const WW_BO_Services = ({textAlign, serviceStyle}) => {
    const {darkTheme} = useContext(themeContext);

    const theme = useTheme();
    const matchesWidth = useMediaQuery(theme.breakpoints.between('md','lg'))

    return (
        <div className="row row--15 service-wrapper">
            {ServiceList.map((val, i) => (
                <div className="col-lg-4 col-md-6 col-sm-12 col-12" key={i}>
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="inner">
                                <div className="content" style={{height: matchesWidth ? '440px' : '350px'}}>
                                    <h4 className="title">
                                        <div to="#service" dangerouslySetInnerHTML={{__html: val.title}}></div>
                                    </h4>
                                    <p className="description" dangerouslySetInnerHTML={{__html: val.description}}></p>
                                </div>
                                <div className="image">
                                    {val.dmImage ? <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        height: '200px',
                                        paddingTop: '30px',
                                        paddingBottom: '5px'
                                    }}>

                                        {darkTheme ? <img src={`${val.dmImage}`} alt="dark mode card Images"/> :
                                            <img src={`${val.lmImage}`} alt="light mode card Images"/>}
                                    </div> : <div></div>
                                    }
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default WW_BO_Services;
