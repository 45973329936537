import React, {useContext} from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import themeContext from "../../context/themeContext";

const ServiceList = [
    {
        dmImage: '/images/service/darkmode/software-code_darkmode.svg',
        lmImage: '/images/service/lightmode/software-code.svg',
        title: 'Embedded Software Development',
        description: 'Extensive experience in\n' +
            'the full product life cycle development projects creating rail spec bare metal and Linux based hardware software systems.'
    },
    {
        dmImage: '/images/service/darkmode/cloud-datacenter_darkmode.svg',
        lmImage: '/images/service/lightmode/cloud-datacenter.svg',
        title: 'Back Office Systems',
        description: 'We specialize in developing on-premise and cloud-based Back Offices with high availability and low operational costs.\n' +
            'Our extensive experience includes building and delivering stable, customer-operated software solutions that are easy to manage.'
    },
    {
        dmImage: '/images/service/darkmode/asset-tracking-icon_darkmode.svg',
        lmImage: '/images/service/lightmode/asset-tracking-icon.svg',
        title: 'Asset Tracking',
        description: 'We have expertise in creating tracking solutions capable of monitoring and controlling mobile and fixed assets, providing critical business information.'
    },
    {
        dmImage: '/images/service/darkmode/business-intelligence_darkmode.svg',
        lmImage: '/images/service/lightmode/business-intelligence.svg',
        title: 'Business Intelligence',
        description: 'We create visualization features for monitoring device status, automating logistics, and boosting productivity for customers.\n' +
            'We analyze your indenture processes, identify inefficiencies, and develop cost-saving improvements.'
    },
    {
        dmImage: '/images/service/darkmode/edge-computing_darkmode.svg',
        lmImage: '/images/service/lightmode/edge-computing.svg',
        title: 'IoT & Edge Computing',
        description: 'We can analyze massive amounts of Real-Time data at the EDGE to preemptively identify issues and mitigate risk. We have diverse experience working with devices, sensors, and computers.'
    },
    {
        dmImage: '/images/service/darkmode/pcb-icon_darkmode.svg',
        lmImage: '/images/service/lightmode/pcb-icon.svg',
        title: 'Hardware Design',
        description: 'We offer custom solutions for electronic device and system development. We specialize in designing and engineering tailored hardware solutions to meet our clients\' unique requirements.'
    },
]
const OurServices = ({textAlign, serviceStyle}) => {
    const {darkTheme} = useContext(themeContext);
    console.log(darkTheme);

    return (
        <div className="row row--15 service-wrapper">
            {ServiceList.map((val, i) => (
                <div className="col-lg-4 col-md-6 col-sm-12 col-12" key={i}>
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="inner">
                                <div className="content">
                                    <h4 className="title">
                                        <div to="#service" dangerouslySetInnerHTML={{__html: val.title}}></div>
                                    </h4>
                                    <p className="description" dangerouslySetInnerHTML={{__html: val.description}}></p>
                                </div>
                                <div className="image">
                                    <div style={{display: 'flex', justifyContent: 'center', height: '200px', paddingTop: '30px', paddingBottom: '5px'}}>

                                        {darkTheme ? <img src={`${val.dmImage}`} alt="dark mode card Images"/> : <img src={`${val.lmImage}`} alt="light mode card Images"/>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default OurServices;
