import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const TimelineData = [
    // {
    //     id: "1",
    //     title: "Discussion ",
    //     description: "Let’s get together for YOUR project",
    // },
    {
        id: "2",
        title: "Project Design",
        description: "Architecting YOUR solution",
    },
    {
        id: "3",
        title: "Execution",
        description: "Implementing YOUR solutions",
    },
    {
        id: "4",
        title: "Testing & Acceptance",
        description: "We test it. YOU approve it",
    },
    {
        id: "5",
        title: "Product Delivery",
        description: "Working with YOUR team to deploy",
    },
    // {
    //     id: "6",
    //     title: "Support",
    //     description: "In person support when YOU need it",
    // },
]

const TimelineTwo = ({classVar}) => {
    return (
        <div className="timeline-style-two bg-color-blackest">
            <div className="row row--0">
                {/* Start Single Progress */}
                {TimelineData.map((data, index) => (
                    <div className={`col-lg-3 col-md-5 rn-timeline-single ${classVar}`} key={index}>
                        <div className="rn-timeline">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                <h6 className="title">{data.title}</h6>
                            </ScrollAnimation>
                            <div className="progress-line">
                                <div className="line-inner"></div>
                            </div>
                            <div className="progress-dot">
                                <div className="dot-level">
                                    <div className="dot-inner"></div>
                                </div>
                            </div>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                <p className="description">{data.description}</p>
                            </ScrollAnimation>
                        </div>
                    </div>
                ))}
                {/* End Single Progress */}
            </div>
        </div>
    )
}
export default TimelineTwo;
