import React, {useContext} from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import themeContext from "../../context/themeContext";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const ServiceList = [
    {
        title: 'Real-time Monitoring',
        description:
            '<b>Track and Equipment Monitoring</b><br/>' +
            '<li>Continuous surveillance of tracks and railway equipment to detect faults and irregularities.</li><br/>' +
            '<b>Environmental Monitoring</b><br/>' +
            '<li>Integration of sensors to monitor weather conditions and environmental factors affecting railway operations.</li><br/>'
    },
    {
        title: 'Asset Types',
        description:
            '<div style="display: flex; justify-content: space-between;">' +
            '<ul style="width: 50%;">' +
            '<li>Defect Detectors</li>' +
            '<li>Recorders</li>' +
            '<li>EOTs</li>' +
            '<li>XP4</li>' +
            '</ul>' +
            '<ul style="width: 50%;">' +
            '<li>GCP</li>' +
            '<li>WSDMM</li>' +
            '<li>DAU</li>' +
            '<li>Any New or Legacy Device</li>' +
            '</ul></div>'
    },
    {
        title: 'Field Device Communications',
        description:
            '<div style="display: flex; justify-content: space-between;">' +
            '<ul style="width: 50%;">' +
            '<li>Genisys</li>' +
            '<li>ATCS Datagram</li>' +
            '<li>MQTT</li>' +
            '<li>REST over HTTP</li>' +
            '</ul>' +
            '<ul style="width: 50%;">' +
            '<li>SNMP</li>' +
            '<li>CoAP</li>' +
            '<li>Sparkplug</li>' +
            '<li>Future Protocols</li>' +
            '</ul></div>'
    },
    {
        title: 'Predictive Maintenance',
        description:
            '<b>Data Analytics</b><br/>' +
            '<li>Use of advanced analytics to predict potential failures before they occur, allowing for preemptive maintenance.</li><br/>' +
            '<b>Machine Learning</b><br/>' +
            '<li>Integration of sensors to monitor weather conditions and environmental factors affecting railway operations.</li><br/>'
    },
    {
        title: 'Incident Management',
        description:
            '<b>Alarm Systems</b><br/>' +
            '<li>Real-time alerts and notifications for immediate response to incidents.</li><br/>' +
            '<b>Response Coordination</b><br/>' +
            '<li>Tools to coordinate and manage responses to incidents, minimizing downtime and ensuring safety.</li><br/>'
    },
    {
        title: 'Data Integration',
        description:
            '<b>Sensor Integration</b><br/>' +
            '<li>Compatibility with various sensors and data sources for a unified monitoring system.</li><br/>' +
            '<b>Data Visualization</b><br/>' +
            '<li>User-friendly dashboards for visualizing data and deriving actionable insights.</li><br/>'
    },
    {
        title: 'Compliance and Reporting',
        description:
            '<b>Regulatory Compliance</b><br/>' +
            '<li>Ensures adherence to industry regulations and standards.</li><br/>' +
            '<b>Automated Reporting</b><br/>' +
            '<li>Generation of detailed reports for audits and compliance checks.</li><br/>'    },
]
const WW_SW_Services = ({textAlign, serviceStyle}) => {
    const {darkTheme} = useContext(themeContext);

    const theme = useTheme();
    const matchesWidth = useMediaQuery(theme.breakpoints.between('md','lg'))

    return (
        <div className="row row--15 service-wrapper">
            {ServiceList.map((val, i) => (
                <div className="col-lg-4 col-md-6 col-sm-12 col-12" key={i}>
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="inner">
                                <div className="content" style={{height: matchesWidth ? '340px' : '300px'}}>
                                    <h4 className="title">
                                        <div to="#service" dangerouslySetInnerHTML={{__html: val.title}}></div>
                                    </h4>
                                    <p className="description" dangerouslySetInnerHTML={{__html: val.description}}></p>
                                </div>
                                <div className="image">
                                    {val.dmImage ? <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        height: '200px',
                                        paddingTop: '30px',
                                        paddingBottom: '5px'
                                    }}>

                                        {darkTheme ? <img src={`${val.dmImage}`} alt="dark mode card Images"/> :
                                            <img src={`${val.lmImage}`} alt="light mode card Images"/>}
                                    </div> : <div></div>
                                    }
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default WW_SW_Services;
