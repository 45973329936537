import React, {Component} from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({text}) => <div>{text}</div>;

class GoogleMapStyle extends Component {
    static defaultProps = {
        center: {
            lat: 30.3322,
            lng: -81.6557,
        },
        zoom: 11
    };

    render() {
        return (
            // Important! Always set the container height explicitly
            <div className="google-map-style-1">
                <GoogleMapReact
                    bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_API_KEY}}
                    defaultCenter={this.props.center}
                    defaultZoom={this.props.zoom}
                >
                    <AnyReactComponent
                        lat={30.3322}
                        lng={-81.6557}
                        text="Outlier Engineering Group"
                    />
                </GoogleMapReact>
            </div>
        );
    }
}

export default GoogleMapStyle;
